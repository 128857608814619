import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainApp from './MainApp/MainApp';
import SubApp1 from './MainApp/SubApp1/SubApp1';
import SubApp2 from './MainApp/SubApp2/SubApp2';
import SubApp3 from './MainApp/SubApp3/SubApp3';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/subapp1/*" element={<SubApp1 />} />
        <Route path="/subapp2/*" element={<SubApp2 />} />
        <Route path="/subapp3/*" element={<SubApp3 />} />
      </Routes>
    </Router>
  );
};

export default App;

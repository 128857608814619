import React, { useState } from 'react';
import { Container, TextField, Button, FormControlLabel, Checkbox, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { toast, ToastContainer } from "react-toastify";
import {getFromAPI,postToAPI} from '../../../Utils/utils'

const LoginPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [inputData, setInputData] = useState({
    userName: "",
    password: "",
    loginType : "export"
  });

  const [validated, setValidated] = useState(false);

  // Function to handle the login process
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      // Validate the form
      const form = document.getElementById("loginForm");
      if (form.checkValidity() === false) {
        setValidated(true); // Set validated to true to show the validation errors
        return; // If form validation fails, return without proceeding to login
      } else {
        setValidated(false); // Reset validated state if form is valid
      }
      
      if(!inputData.userName)
      {
        toast.error("Please Enter UserName",'error')
        return;
      }
      if(!inputData.password)
        {
          toast.error("Please Enter Password",'error')
          return;
        }

      // Make a POST request to the login endpoint with user credentials
      const response = await postToAPI("login/", {
        userName: inputData.userName,
        password: inputData.password,
        loginType : "export"

      });

      if (response.status) {
        var parsedResponse = response.data;
        localStorage.setItem('USER_Name', parsedResponse.userName);
        // If login is successful, parse the response and navigate to the dashboard
         navigate('/subApp3/datatable');

      } else {
        // If login fails, show an alert with the error message
        toast.error("Invalid userName Or Password", 'error');
      }
    } catch (error) {
      toast.error("Invalid UserName Or Password", 'error');
    }
  };

  // Function to handle changes in the input fields
  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <>
    <Container maxWidth="lg" className="login-container">
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Paper className="login-paper">
            <h2>Login</h2>
            <form className="login-form" id="loginForm" onSubmit={handleLogin}>
              <TextField
                label="Username"
                name="userName" // Added name attribute
                variant="outlined"
                margin="normal"
                fullWidth
                value={inputData.userName}
                onChange={handleChange}
              />
              <TextField
                label="Password"
                name="password" // Added name attribute
                type="password"
                variant="outlined"
                margin="normal"
                fullWidth
                value={inputData.password}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className="login-button"
              >
                Login
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
     <ToastContainer position="top-left" autoClose={3000} hideProgressBar theme="colored" />
     </>
  );
};

export default LoginPage;

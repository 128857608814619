// src/SubApp1/SubApp1.js
import React from 'react';
import { Router, Route, Routes } from 'react-router-dom';
import DataTable from './components/DataTable';
import LoginPage from './components/LoginPage';
import './SubApp1.css';

const SubApp1 = () => {
  return (
    <div className="App">
      <header className="App-header" style={{width : '100%'}}>
        Waste Management System
      </header>
      <main>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/datatable" element={<DataTable />} />
        </Routes>
      </main>
    </div>
  );
};

export default SubApp1;

import React, { useState } from 'react';
import { Container, Typography, Menu, MenuItem, ListItemIcon, Grid, TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { Logout, Lock, Visibility, VisibilityOff, ArrowBack } from '@mui/icons-material';
import logo from '../../../user1.png'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { putToAPI } from '../../../Utils/utils'; // Import API calls from utils.js
import { headingOFData } from '../../../Config/config'; // Ensure this import is correct

const ChangePassword = () => {
  const userName = localStorage.getItem('USER_Name');
  const currentPassword = localStorage.getItem('Password');
  const navigate = useNavigate();

  // State hooks
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Menu handlers
  const handleMenuOpen = (event) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);

  // Logout handler
  const handleLogout = () => {
    navigate('/subApp2'); // Adjust the route as needed
  };

  const handleBack = () => {
    navigate('/subApp2/DataTable/'); // Adjust the route as needed
  };

  // Change password handler
  const handleChangePassword = () => {
    if (!oldPassword) {
      toast.error("Please enter old password");
      return;
    }
    if (!newPassword) {
      toast.error("Please enter new password");
      return;
    }
    if (!confirmPassword) {
      toast.error("Please enter confirm password");
      return;
    }

    if (currentPassword === newPassword) {
      toast.error('New password cannot be the same as the old password');
      return;
    }

    if (oldPassword !== currentPassword) {
      toast.error('Old password is incorrect');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('New passwords do not match with confirm password');
      return;
    }

    // Update API call here if needed
    putToAPI('login/', { userName, password: newPassword })
      .then(response => {
        if (response.status) {
          toast.success('Password changed successfully');
          navigate('/subApp2'); // Redirect after successful password change
        } else {
          toast.error('Error changing password');
        }
      })
      .catch(error => {
        console.error('Error changing password:', error);
        toast.error('Error changing password');
      });
  };

  // Helper function to prevent spaces in input
  const handleInputChange = (setter) => (e) => {
    const value = e.target.value.replace(/\s/g, ''); // Remove all spaces
    setter(value);
  };

  return (
    <>
      <Container maxWidth={false} style={{ padding: '0 16px', height: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',margin : '30px' }}>
        <Typography variant="h2" style={{ fontSize: '1.5rem', fontWeight: 'bold'}}>
            {headingOFData}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item>
              <Typography variant="h6" style={{ marginRight: '10px' }}>
                {userName}
              </Typography>
            </Grid>
            <img
              src={logo}
              alt="Logo"
              style={{ width: '30px', marginRight: '10px', cursor: 'pointer' }}
              onClick={handleMenuOpen}
            />
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <Lock style={{ fontSize: '1.2rem' }} />
                </ListItemIcon>
                Change Password
              </MenuItem>
              <MenuItem onClick={handleBack}>
                <ListItemIcon>
                  <ArrowBack style={{ fontSize: '1.2rem' }} />
                </ListItemIcon>
                Back
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout style={{ fontSize: '1.2rem' }} />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Typography variant="h6">Change Password</Typography>
          <TextField
            label="Old Password"
            type={showOldPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            margin="normal"
            value={oldPassword}
            onChange={handleInputChange(setOldPassword)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="New Password"
            type={showNewPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            margin="normal"
            value={newPassword}
            onChange={handleInputChange(setNewPassword)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            margin="normal"
            value={confirmPassword}
            onChange={handleInputChange(setConfirmPassword)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangePassword}
            style={{ marginTop: '20px' }}
          >
            Change Password
          </Button>
        </div>
      </Container>
      <ToastContainer position="top-left" autoClose={3000} hideProgressBar theme="colored" />
    </>
  );
};

export default ChangePassword;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DataTable from './components/DataTable';
import LoginPage from './components/LoginPage';
import ChangePassword from './components/changePassword'
import './SubApp2.css';

const SubApp2 = () => {
  return (
    <div className="App">
      <header className="App-header" style={{width : '100%'}}>
        Waste Management System
      </header>
      <main>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/datatable" element={<DataTable />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />

        </Routes>
      </main>
    </div>
  );
};

export default SubApp2;

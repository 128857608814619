// src/MainApp/MainApp.js
import React from 'react';

const MainApp = () => {
  return (
    <div>
    </div>
  );
};

export default MainApp;

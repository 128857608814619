import React, { useMemo, useState, useEffect } from 'react';
import { useTable, usePagination,useSortBy } from 'react-table';
import { Container, TextField, Button, IconButton, Menu, MenuItem, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography, TablePagination } from '@mui/material';
import { Save, Description, InsertDriveFile, Print, CopyAll } from '@mui/icons-material';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { fieldDefinitionsforWebiste3, defaultData, headingOFData, SavefileName,defaultFromDate,defaultToDate } from '../../../Config/config'; // import all dynamic data from config.js
import logo from '../../../user1.png'; // Correct path to the image
import { saveAs } from 'file-saver';
import { getFromAPI, postToAPI } from '../../../Utils/utils' // Import api call from the utils.js
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ExcelJS from 'exceljs';
import { format } from 'date-fns'; // Make sure to install date-fns
//import { saveAs } from 'file-saver';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Create the function for datatable

const DataTable1 = () => {

  // Initalize the data and set data.

  const [data, setData] = useState([
    {
      id: '',
      date: '',
      time: '',
      ownerName: '',
      businessName: '',
      address: '',
      mobileNo: '',
      wardNo: '',
      zoneNo: '',
      category: '',
      modeOfPayment: '',
      billAmount: '',
      paymentReceived: '',
      deviceName: '',
      verified: false, // Flag to indicate verification status
    },
  ]);

  // Initalize the navigate function for navigate to another page.
  const navigate = useNavigate()

  const [paymentMode, setPaymentMode] = useState('all');  // Set the payment mode for filteration.
  const [filteData, setFilteredData] = useState(data); // Initalize the filter and setFilteredData.
  const [device, setDevice] = useState('all');  // Initalize for filter according to device.
  const [deviceOptions, setDeviceOptions] = useState([]); // State for device options
  const [page, setPage] = useState(0);   // Set the page on pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);  // Initalize the rowperpage.
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [searchTerm, setSearchTerm] = useState('');
  const userName = localStorage.getItem('USER_Name')
  const [paymentOption , setPaymentOption] = useState([])

  const filteredData = useMemo(() => {
    return data.filter(entry => {
      const paymentModeMatch = paymentMode === 'all' || entry.modeOfPayment === paymentMode;
      const deviceMatch = device === 'all' || entry.deviceName === device;
      const searchTermMatch = Object.values(entry).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
      return paymentModeMatch && deviceMatch && searchTermMatch;
    });
  }, [data, paymentMode, device, searchTerm]);


  // Initalize the coloums of the table.
  const columns = useMemo(() => fieldDefinitionsforWebiste3, []);

  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page: tablePage,
    gotoPage,
    setPageSize,
    state: { sortBy },
    setSortBy
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 5, sortBy: [] },
    },
    useSortBy,
    usePagination
  );


  // Function for handle generate the data.
  // Function for handle generate the data.
  const handleGenerate = () => {
    
    if (new Date(fromDate) > new Date(toDate)) {
      toast.error("From Date should not be greater than To Date", 'error');
      return;
    }
    
    setPage(0); // Reset pagination to the first page
    fetchData();
  };

  
  // Fucntion for fetchdata, according to the selected params
  const fetchData = async () => {
    try {
      const params = new URLSearchParams({
        from_date: fromDate,
        to_date: toDate,
        payment_mode: paymentMode,
        device: device
      }).toString();

      const response = await getFromAPI(`solidmanagementdatafromwebsite/?${params}`);
      setData(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchDeviceOptions();
  }, []);

  const fetchDeviceOptions = async () => {
    try {
      const User_Id = localStorage.getItem('USER_Name');
       if (!User_Id) {
          navigate('/subapp3');
          return; // Navigate away if no employee ID is found
          }
      const response = await getFromAPI('applogin/');
      const devices = [...new Set(response.map(item => item.user_name))];
      setDeviceOptions(devices);
    } catch (error) {
      console.error('Error fetching device options:', error);
    }
  };

  useEffect(() => {
    fetchpaymentOptions();
  }, []);


  const fetchpaymentOptions = async () => {
    try {
      const response = await getFromAPI('paymentmode/');
      const payment = [...new Set(response.map(item => item.Name))];
      setPaymentOption(payment);
    } catch (error) {
      console.error('Error fetching device options:', error);
    }
  };


  // Function for handle save ad pdf.
  const handleSaveAsPDF = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const reportData = {
      reportFrom: fromDate,
      reportTo: toDate,
      reportDate: currentDate,
      entries: filteredData
    };

    const documentDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: [40, 60, 40, 60],
      content: [
        { text: { SavefileName }, style: 'header' },
        'Daily Collection Report',
        `Report From: ${reportData.reportFrom}  To: ${reportData.reportTo}  Report Date: ${reportData.reportDate}`,
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: Array(14).fill('auto'),
            body: [
              fieldDefinitionsforWebiste3.map(field => field.Header),
              ...reportData.entries.map(entry => fieldDefinitionsforWebiste3.map(field => entry[field.accessor]))
            ]
          },
          layout: 'lightHorizontalLines'
        }
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 8]
        },
        tableExample: {
          margin: [0, 4, 0, 14]
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black'
        }
      },
      defaultStyle: {
        fontSize: 8
      }
    };

    pdfMake.createPdf(documentDefinition).download(`${SavefileName}.pdf`);
  };

  // Function for handle the print PDF.  
  const handlePrintPDF = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const reportData = {
      reportFrom: fromDate,
      reportTo: toDate,
      reportDate: currentDate,
      entries: filteredData
    };

    const documentDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: [40, 60, 40, 60],
      content: [
        { text: { SavefileName }, style: 'header' },
        'Daily Collection Report',
        `Report From: ${reportData.reportFrom}  To: ${reportData.reportTo}  Report Date: ${reportData.reportDate}`,
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: Array(14).fill('auto'),
            body: [
              fieldDefinitionsforWebiste3.map(field => field.Header),
              ...reportData.entries.map(entry => fieldDefinitionsforWebiste3.map(field => entry[field.accessor]))
            ]
          },
          layout: 'lightHorizontalLines'
        }
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 8]
        },
        tableExample: {
          margin: [0, 4, 0, 14]
        },
        tableHeader: {
          bold: true,
          fontSize: 9,
          color: 'black'
        }
      },
      defaultStyle: {
        fontSize: 7
      }
    };

    const pdfDoc = pdfMake.createPdf(documentDefinition);
    pdfDoc.print();
  };

 
  // Function for handle the save as excel.
  const handleSaveAsExcel = async () => {
    const now = new Date();
    const currentDate = format(now, 'dd-MMM-yyyy'); // Example: 12-Jul-2024
    const currentTime = format(now, 'HH:mm'); // Example: 11:07
    const currentDateTime = `${currentDate} ${currentTime}`;
  
    // Extract headers from fieldDefinitions
    const headers = fieldDefinitionsforWebiste3.map(field => field.Header);
    const accessors = fieldDefinitionsforWebiste3.map(field => field.accessor);
  
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('data');
    
    // Define extra rows
    const extraRow1 = ['Daily Collection Report'];
    const extraRow2 = [`Report From: ${fromDate} To: ${toDate} Report Date: ${currentDateTime}`];
    
    // Add extra rows to the worksheet
    worksheet.addRow(extraRow1).commit();
    worksheet.addRow(extraRow2).commit();
    
    // Add headers
    worksheet.addRow(headers).commit();
    
    // Add data rows
    filteredData.forEach(item => {
      worksheet.addRow(accessors.map(accessor => item[accessor])).commit();
    });
    
    // Merge cells for the first two rows
    worksheet.mergeCells('A1', `${String.fromCharCode(65 + headers.length - 1)}1`);
    worksheet.mergeCells('A2', `${String.fromCharCode(65 + headers.length - 1)}2`);
    
    // Apply styles
    const headerStyle = {
      alignment: { horizontal: 'center', vertical: 'center' },
      font: { bold: true }
    };
    
    worksheet.getCell('A1').style = headerStyle;
    worksheet.getCell('A2').style = headerStyle;
    
    // Set column width
    const colWidth = 20; // Adjust this value as needed
    headers.forEach((_, index) => {
      worksheet.getColumn(index + 1).width = colWidth;
    });
    
    // Generate Excel file buffer
    const excelBuffer = await workbook.xlsx.writeBuffer();
    
    // Create the file name
    const fileName = `${SavefileName}_${currentDate}.xlsx`;
    
    // Save the file
    saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), fileName);
  };
  // Function for handling save as CSV
  const handleSaveAsCSV = () => {
    // Define headers
    const headers = fieldDefinitionsforWebiste3.map(field => field.Header);

    // Define accessors
    const accessors = fieldDefinitionsforWebiste3.map(field => field.accessor);
  
    // Create an array of arrays with headers and data
    const sheetData = [headers, ...data.map(item => accessors.map(accessor => item[accessor]))];
  
    // Convert array of arrays to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  
    // Create a workbook and add the worksheet
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  
    // Generate the Excel buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
  
    // Get the current date
    const currentDate = new Date().toISOString().split('T')[0];
  
    // Create the file name
    const fileName = `${SavefileName}_${currentDate}.csv`;
  
    // Save the file
    saveAs(new Blob([excelBuffer], { type: 'text/csv;charset=utf-8;' }), fileName);
};

  const handleCopyToClipboard = async () => {
    const now = new Date();
    const currentDate = format(now, 'dd-MMM-yyyy'); // Example: 12-Jul-2024
    const currentTime = format(now, 'HH:mm'); // Example: 11:07
    const currentDateTime = `${currentDate} ${currentTime}`;
  
    // Extract headers from fieldDefinitions
    const headers = fieldDefinitionsforWebiste3.map(field => field.Header);
    const accessors = fieldDefinitionsforWebiste3.map(field => field.accessor);
  
    // Prepare the data
    let tsv = 'Daily Collection Report\n';
    tsv += `Report From: ${fromDate} To: ${toDate} Report Date: ${currentDateTime}\n`;
    tsv += headers.join('\t') + '\n';
    
    filteredData.forEach(item => {
      const row = accessors.map(accessor => item[accessor]).join('\t');
      tsv += row + '\n';
    });
  
    // Copy the data to the clipboard
    navigator.clipboard.writeText(tsv).then(() => {
      toast.success('Data copied to clipboard in Excel format', 'success');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  // Function for handle search.
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // Reset pagination to first page when search term changes
    setPage(0);
  };

  // Function for set the page according to change the page.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function for handle the changes in Row per page.
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Function for filtered data according to search.
  useEffect(() => {
    const filtered = data.filter((item) => {
      const lowercasedTerm = searchTerm.toLowerCase();
      return (
        item.id.toString().toLowerCase().includes(lowercasedTerm) ||
        item.date.toLowerCase().includes(lowercasedTerm) ||
        item.time.toLowerCase().includes(lowercasedTerm) ||
        item.ownerName.toLowerCase().includes(lowercasedTerm) ||
        item.businessName.toLowerCase().includes(lowercasedTerm) ||
        item.address.toLowerCase().includes(lowercasedTerm) ||
        item.mobileNo ||
        item.wardNo ||
        item.zoneNo ||
        item.category.toLowerCase().includes(lowercasedTerm) ||
        item.modeOfPayment.toLowerCase().includes(lowercasedTerm) ||
        item.billAmount.toString().toLowerCase().includes(lowercasedTerm) ||
        item.paymentReceived.toString().toLowerCase().includes(lowercasedTerm) ||
        item.deviceName.toLowerCase().includes(lowercasedTerm)
      );
    });
    setFilteredData(filtered);
  }, [data, searchTerm]);


  useEffect(() => {
    setPageSize(rowsPerPage);
    gotoPage(page);
  }, [rowsPerPage, page, setPageSize, gotoPage]);

  // Function for handle the logout functionality.
   // Function for handle the logout functionality.
   const handleLogout = async () => { 
    const response =  await postToAPI("logout/")
  
      if(response.status)
      {
        navigate("/subapp3")
        localStorage.removeItem('USER_Name');
      }  
  };

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  // Calculate total collection
  const totalpaymentCollection = useMemo(() => {
    return filteredData.reduce((total, entry) => total + parseFloat(entry.paymentReceived) || 0, 0);
  }, [filteredData]);

  const totalbillCollection = useMemo(() => {
    return filteredData.reduce((total, entry) => total + parseFloat(entry.billAmount) || 0, 0);
  }, [filteredData]);

  return (
    <>
      <Container  maxWidth={false} style={{ padding: '0 16px', height: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',margin:'10px' }}>
        <h2 style={{fontSize: '1.5rem' }}>{headingOFData}</h2>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item>
              <Typography variant="h6" style={{marginRight: '10px' }}>{userName}</Typography>
            </Grid>
            <img
              src={logo}
              alt="Logo"
              className="profile-logo"
              style={{ width: '30px', marginRight: '10px', cursor: 'pointer' }}
              onClick={handleMenuOpen} // Open menu on logo click
            />
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>


        <Grid container alignItems="center">
          <Grid item style={{ marginLeft: 'auto' }}>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <TextField
            label="From Date"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="To Date"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Payment Mode"
            select
            value={paymentMode}
            onChange={(e) => setPaymentMode(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: paymentOption.length > 3 ? '120px' : 'auto',
                  },
                },
              },
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {paymentOption.map((Name) => (
              <MenuItem key={Name} value={Name}>
                {Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Device"
            select
            value={device}
            onChange={(e) => setDevice(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: deviceOptions.length > 3 ? '120px' : 'auto',
                  },
                },
              },
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {deviceOptions.map((user_name) => (
              <MenuItem key={user_name} value={user_name}>
                {user_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Search"
            fullWidth
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Grid item xs="auto">
          <Tooltip title="Copy Data" arrow>
            <IconButton onClick={handleCopyToClipboard} size="small" disabled={filteredData.length === 0}>
              <CopyAll />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="Print PDF" arrow>
            <IconButton onClick={handlePrintPDF} size="small" disabled={filteredData.length === 0}>
              <Print />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="Save as Excel" arrow>
            <IconButton onClick={handleSaveAsExcel} size="small" disabled={filteredData.length === 0}>
              <Description />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="Save as CSV" arrow>
            <IconButton onClick={handleSaveAsCSV} size="small" disabled={filteredData.length === 0}>
              <InsertDriveFile />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="Save as PDF" arrow>
            <IconButton onClick={handleSaveAsPDF} size="small" disabled={filteredData.length === 0}>
              <Save />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <Button variant="contained" color="primary" size="small" onClick={handleGenerate}>
            Generate
          </Button>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
      </Grid>
        


        <Paper style={{ marginTop: '20px', flexGrow: 1, overflow: 'auto' }}>
         
        <TableContainer component={Paper} style={{ maxHeight: '350px', overflow: 'auto' }}>
          <Table {...getTableProps()} stickyHeader size="small">
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ 
                        fontSize: '1rem', 
                        padding: '20px', 
                        borderRight: '1px solid #ddd', 
                        fontWeight: 'bold', 
                        whiteSpace: 'nowrap', 
                        backgroundColor: '#007bff', 
                        color: '#fff' 
                      }}
                      title=""  // Ensure title attribute is empty or not set
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {filteredData.length > 0 ? (
                tablePage.map(row => {
                  prepareRow(row);
                  return (
                    <TableRow key={row.id} {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <TableCell 
                          key={cell.column.id} 
                          {...cell.getCellProps()} 
                          style={{ fontSize: '0.8rem', padding: '4px', borderRight: '1px solid rgba(224, 224, 224, 1)',textAlign: 'center' }}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={headerGroups[0].headers.length} style={{ textAlign: 'center', padding: '20px' }}>
                    <Typography variant="h6">
                      No data available in table
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>

        <Grid container spacing={1} style={{ marginTop: '6px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Grid item xs="auto" >
        <TextField
          label="Total Bill Collection"
          size="small"
          value={totalbillCollection.toFixed(2)}
          InputProps={{ readOnly: true }} // Optionally make it read-only
         />
        </Grid>

        <Grid item xs="auto" style={{flexGrow: 1 }}>
         <TextField
          label="Total Received Collection"
          size="small"
          value={totalpaymentCollection.toFixed(2)}
          InputProps={{ readOnly: true }} // Optionally make it read-only
         />
        </Grid>

          <Grid item xs="auto" style={{ textAlign: 'right' }}>
            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>

      </Container>
      <ToastContainer position="top-left" autoClose={3000} hideProgressBar theme="colored" />
    </>
  );
};

export default DataTable1;

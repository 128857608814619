// fieldConfig.js

export const fieldDefinitions = [
    { Header: 'SR NO', accessor: 'srNo' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Time', accessor: 'time' },
    { Header: 'Owner Name', accessor: 'ownerName' },
    { Header: 'BusinessName', accessor: 'businessName' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Mobile no', accessor: 'mobileNo' },
    { Header: 'Ward No', accessor: 'wardNo' },
    { Header: 'Zone No', accessor: 'zoneNo' },
    { Header: 'Category', accessor: 'category' },
    { Header: 'Mode Of Payment', accessor: 'modeOfPayment' },
    { Header: 'Bill Amount', accessor: 'billAmount' },
    { Header: 'Payment Received', accessor: 'paymentReceived' },
    { Header: 'Device Name', accessor: 'deviceName' },
  ];

  export const fieldDefinitionsforWebiste2 = [
    { Header: 'SR NO', accessor: 'id' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Time', accessor: 'time' },
    { Header: 'Owner Name', accessor: 'ownerName' },
    { Header: 'BusinessName', accessor: 'businessName' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Mobile no', accessor: 'mobileNo' },
    { Header: 'Ward No', accessor: 'wardNo' },
    { Header: 'Zone No', accessor: 'zoneNo' },
    { Header: 'Category', accessor: 'category' },
    { Header: 'Mode Of Payment', accessor: 'modeOfPayment' },
    { Header: 'Bill Amount', accessor: 'billAmount' },
    { Header: 'Payment Received', accessor: 'paymentReceived' },
    { Header: 'Device Name', accessor: 'deviceName' },
  ];

  export const fieldDefinitionsforWebiste3 = [
    { Header: 'SR NO', accessor: 'id' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Time', accessor: 'time' },
    { Header: 'Owner Name', accessor: 'ownerName' },
    { Header: 'BusinessName', accessor: 'businessName' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Mobile no', accessor: 'mobileNo' },
    { Header: 'Ward No', accessor: 'wardNo' },
    { Header: 'Zone No', accessor: 'zoneNo' },
    { Header: 'Category', accessor: 'category' },
    { Header: 'Mode Of Payment', accessor: 'modeOfPayment' },
    { Header: 'Bill Amount', accessor: 'billAmount' },
    { Header: 'Payment Received', accessor: 'paymentReceived' },
    { Header: 'Device Name', accessor: 'deviceName' },
  ];
  
  export const defaultData = fieldDefinitions.reduce((acc, field) => {
    acc[field.accessor] = '';
    return acc;
  }, { verified: false }); // Add the verified flag to default data
 
  export const headingOFData = 'Bikaner Nagar Nigam User Charges Collection Report';

  // Name of excel file save into the system. 
  export const SavefileName = 'Daily Collection Report';
 
  // No data show display when their is no data in the table.
  export const noDataToShow = 'No data to show'; 

  //
  export const titleOfDataCopied= 'Data copied to clipboard in Excel format';

  // Default date range configuration
export const defaultFromDate = '2024-01-12';
export const defaultToDate = '2024-12-31';

import React, { useMemo, useState, useEffect } from 'react';
import { useTable, usePagination,useSortBy } from 'react-table';
import {
  Container, TextField, Button, IconButton, Menu, MenuItem, Paper, TableContainer,
  Table, TableHead, TableRow, TableCell, TableBody, Grid, Tooltip, Typography, TablePagination
} from '@mui/material';
import { Save, Description, InsertDriveFile, Print, CopyAll, CloudUpload } from '@mui/icons-material';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getFromAPI, postToAPI } from '../../../Utils/utils';
import { fieldDefinitions, defaultData, headingOFData, SavefileName,defaultFromDate,defaultToDate } from '../../../Config/config';
import logo from '../../../user1.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ExcelJS from 'exceljs';
import { format } from 'date-fns'; // Make sure to install date-fns

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DataTable = () => {
  const [data, setData] = useState([
    {
      srNo: '',
      date: '',
      time: '',
      ownerName: '',
      businessName: '',
      address: '',
      mobileNo: '',
      wardNo: '',
      zoneNo: '',
      category: '',
      modeOfPayment: '',
      billAmount: '',
      paymentReceived: '',
      deviceName: '',
      verified: false, // Flag to indicate verification status
    },
  ]);  
  const [importedData, setImportedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paymentMode, setPaymentMode] = useState('all');
  const [device, setDevice] = useState('all');
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [paymentOption , setPaymentOption] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const userName = localStorage.getItem('USER_Name');
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const columns = useMemo(() => fieldDefinitions, []);
  const [isDataValid, setIsDataValid] = useState(true); // New state for button disabled status
  const navigate = useNavigate();

  // Memoized filtered data based on filters
  const filteredData = useMemo(() => {
    return importedData.filter(entry => {
      const paymentModeMatch = paymentMode === 'all' || entry.modeOfPayment === paymentMode;
      const deviceMatch = device === 'all' || entry.deviceName === device;
      const searchTermMatch = Object.values(entry).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
      return paymentModeMatch && deviceMatch && searchTermMatch;
    });
  }, [importedData, paymentMode, device, searchTerm]);

  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page: tablePage,
    gotoPage,
    setPageSize,
    state: { sortBy },
    setSortBy
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 5, sortBy: [] },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(rowsPerPage);
    gotoPage(page);
  }, [rowsPerPage, page, setPageSize, gotoPage]);

  useEffect(() => {
    fetchDeviceOptions();
  }, []);

  const fetchDeviceOptions = async () => {
    try {
      const User_Id = localStorage.getItem('USER_Name');
       if (!User_Id) {
          navigate('/subapp1');
          return; // Navigate away if no employee ID is found
          }
      const response = await getFromAPI('applogin/');
      const devices = [...new Set(response.map(item => item.user_name))];
      setDeviceOptions(devices);
    } catch (error) {
      console.error('Error fetching device options:', error);
    }
  };

  useEffect(() => {
    fetchpaymentOptions();
  }, []);


  const fetchpaymentOptions = async () => {
    try {
      const response = await getFromAPI('paymentmode/');
      const payment = [...new Set(response.map(item => item.Name))];
      setPaymentOption(payment);
    } catch (error) {
      console.error('Error fetching device options:', error);
    }
  };

   // Function for handle save ad pdf.
   const handleSaveAsPDF = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const reportData = {
      reportFrom: fromDate,
      reportTo: toDate,
      reportDate: currentDate,
      entries: filteredData
    };

    const documentDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: [40, 60, 40, 60],
      content: [
        { text: { SavefileName }, style: 'header' },
        'Daily Collection Report',
        `Report From: ${reportData.reportFrom}  To: ${reportData.reportTo}  Report Date: ${reportData.reportDate}`,
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: Array(14).fill('auto'),
            body: [
              fieldDefinitions.map(field => field.Header),
              ...reportData.entries.map(entry => fieldDefinitions.map(field => entry[field.accessor]))
            ]
          },
          layout: 'lightHorizontalLines'
        }
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 8]
        },
        tableExample: {
          margin: [0, 4, 0, 14]
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black'
        }
      },
      defaultStyle: {
        fontSize: 8
      }
    };

    pdfMake.createPdf(documentDefinition).download(`${SavefileName}.pdf`);
  };

 // Function for handle the print PDF.  
 const handlePrintPDF = () => {
  const currentDate = new Date().toISOString().split('T')[0];
  const reportData = {
    reportFrom: fromDate,
    reportTo: toDate,
    reportDate: currentDate,
    entries: filteredData
  };

  const documentDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [40, 60, 40, 60],
    content: [
      { text: { SavefileName }, style: 'header' },
      'Daily Collection Report',
      `Report From: ${reportData.reportFrom}  To: ${reportData.reportTo}  Report Date: ${reportData.reportDate}`,
      {
        style: 'tableExample',
        table: {
          headerRows: 1,
          widths: Array(14).fill('auto'),
          body: [
            fieldDefinitions.map(field => field.Header),
            ...reportData.entries.map(entry => fieldDefinitions.map(field => entry[field.accessor]))
          ]
        },
        layout: 'lightHorizontalLines'
      }
    ],
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 8]
      },
      tableExample: {
        margin: [0, 4, 0, 14]
      },
      tableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black'
      }
    },
    defaultStyle: {
      fontSize: 8
    }
  };

  const pdfDoc = pdfMake.createPdf(documentDefinition);
  pdfDoc.print();
};

  // Function for handle the save as excel.
 // Function for handle the save as excel.
 const handleSaveAsExcel = async () => {
  const now = new Date();
  const currentDate = format(now, 'dd-MMM-yyyy'); // Example: 12-Jul-2024
  const currentTime = format(now, 'HH:mm'); // Example: 11:07
  const currentDateTime = `${currentDate} ${currentTime}`;

  // Extract headers from fieldDefinitions
  const headers = fieldDefinitions.map(field => field.Header);
  const accessors = fieldDefinitions.map(field => field.accessor);

  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('data');
  
  // Define extra rows
  const extraRow1 = ['Daily Collection Report'];
  const extraRow2 = [`Report From: ${fromDate} To: ${toDate} Report Date: ${currentDateTime}`];
  
  // Add extra rows to the worksheet
  worksheet.addRow(extraRow1).commit();
  worksheet.addRow(extraRow2).commit();
  
  // Add headers
  worksheet.addRow(headers).commit();
  
  // Add data rows
  filteredData.forEach(item => {
    worksheet.addRow(accessors.map(accessor => item[accessor])).commit();
  });
  
  // Merge cells for the first two rows
  worksheet.mergeCells('A1', `${String.fromCharCode(65 + headers.length - 1)}1`);
  worksheet.mergeCells('A2', `${String.fromCharCode(65 + headers.length - 1)}2`);
  
  // Apply styles
  const headerStyle = {
    alignment: { horizontal: 'center', vertical: 'center' },
    font: { bold: true }
  };
  
  worksheet.getCell('A1').style = headerStyle;
  worksheet.getCell('A2').style = headerStyle;
  
  // Set column width
  const colWidth = 20; // Adjust this value as needed
  headers.forEach((_, index) => {
    worksheet.getColumn(index + 1).width = colWidth;
  });
  
  // Generate Excel file buffer
  const excelBuffer = await workbook.xlsx.writeBuffer();
  
  // Create the file name
  const fileName = `${SavefileName}_${currentDate}.xlsx`;
  
  // Save the file
  saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), fileName);
};
// Function for handling save as CSV
const handleSaveAsCSV = () => {
  // Define headers
  const headers = fieldDefinitions.map(field => field.Header);

  // Define accessors
  const accessors = fieldDefinitions.map(field => field.accessor);

  // Create an array of arrays with headers and data
  const sheetData = [headers, ...data.map(item => accessors.map(accessor => item[accessor]))];

  // Convert array of arrays to worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

  // Create a workbook and add the worksheet
  const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

  // Generate the Excel buffer
  const excelBuffer = XLSX.write(workbook, { bookType: 'csv', type: 'array' });

  // Get the current date
  const currentDate = new Date().toISOString().split('T')[0];

  // Create the file name
  const fileName = `${SavefileName}_${currentDate}.csv`;

  // Save the file
  saveAs(new Blob([excelBuffer], { type: 'text/csv;charset=utf-8;' }), fileName);
};


const handleCopyToClipboard = async () => {
  const now = new Date();
  const currentDate = format(now, 'dd-MMM-yyyy'); // Example: 12-Jul-2024
  const currentTime = format(now, 'HH:mm'); // Example: 11:07
  const currentDateTime = `${currentDate} ${currentTime}`;

  // Extract headers from fieldDefinitions
  const headers = fieldDefinitions.map(field => field.Header);
  const accessors = fieldDefinitions.map(field => field.accessor);

  // Prepare the data
  let tsv = 'Daily Collection Report\n';
  tsv += `Report From: ${fromDate} To: ${toDate} Report Date: ${currentDateTime}\n`;
  tsv += headers.join('\t') + '\n';
  
  filteredData.forEach(item => {
    const row = accessors.map(accessor => item[accessor]).join('\t');
    tsv += row + '\n';
  });

  // Copy the data to the clipboard
  navigator.clipboard.writeText(tsv).then(() => {
    toast.success('Data copied to clipboard in Excel format', 'success');
  }, (err) => {
    console.error('Could not copy text: ', err);
  });
};


// Function for handle search.
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // You can add additional logic here to handle the search functionality,
    // such as filtering data based on the searchTerm.
    setPage(0);

  };

// Function for filtered data according to search.
// Function to convert decimal time to HH:MM:SS format
const convertDecimalToTime = (decimal) => {
  const hours = Math.floor(decimal * 24);
  const minutes = Math.floor((decimal * 24 - hours) * 60);
  const seconds = Math.round((((decimal * 24 - hours) * 60) - minutes) * 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
// Function to convert Excel date serial number to YYYY-MM-DD format
const convertDateToYMD = (serial) => {
  const startDate = new Date(1899, 11, 30); // Excel date serial number start date
  const date = new Date(startDate.getTime() + serial * 24 * 60 * 60 * 1000);
  
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    if (!file || !/\.xlsx$|\.xls$|\.csv/.test(file.name)) {
      toast.error('Please upload a valid Excel file (.xlsx or .xls)', {
      });
      e.target.value = ''; // Reset the file input value
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Skip the first two rows (1-based index, so row 3 is index 2)
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        range.s.r = 2; // Set the start row to 2 (third row)

        // Convert the worksheet to JSON, starting from the third row
        const importedData = XLSX.utils.sheet_to_json(worksheet, { range: range });

        // Define a mapping from Excel header names to your field definitions
        const headerMapping = {
          'SR NO': 'srNo',
          'Date': 'date',
          'Time': 'time',
          'Owner Name': 'ownerName',
          'BusinessName': 'businessName',
          'Address': 'address',
          'Mobile no': 'mobileNo',
          'Ward No': 'wardNo',
          'Zone No': 'zoneNo',
          'Category': 'category',
          'Mode Of Payment': 'modeOfPayment',
          'Bill Amount': 'billAmount',
          'Payment Received': 'paymentReceived',
          'Device Name': 'deviceName'
        };

        // Trim headers in imported data to map correctly
        const trimmedImportedData = importedData.map(entry => {
          const trimmedEntry = {};
          Object.keys(entry).forEach(key => {
            trimmedEntry[key.trim()] = entry[key];
          });
          return trimmedEntry;
        });

        // Map imported data to the desired format
        const formattedData = trimmedImportedData.map((entry, index) => {
          const mappedEntry = {};
          Object.keys(headerMapping).forEach(header => {
            if (entry[header] !== undefined) {
              // Convert decimal time to HH:MM:SS if applicable
              if (headerMapping[header] === 'time' && typeof entry[header] === 'number') {
                mappedEntry[headerMapping[header]] = convertDecimalToTime(entry[header]);
              }
              // Convert Excel date to YYYY-MM-DD if applicable
              else if (headerMapping[header] === 'date' && typeof entry[header] === 'number') {
                mappedEntry[headerMapping[header]] = convertDateToYMD(entry[header]);
              } else {
                mappedEntry[headerMapping[header]] = entry[header];
              }
            }
          });
          return {
            ...mappedEntry,
            sRNo: index + 1,
            verified: false
          };
        });

        setImportedData(formattedData);
        setPage(0);   // Set the pagination when import the file.
        e.target.value = ''; // Reset the file input value

        // Check if the imported data is valid
        const isValid = formattedData.every(entry =>
          Object.values(headerMapping).every(field => entry[field])
        );
        if (!isValid) {
          toast.error('Invalid Format', {
          });
        }
        setIsDataValid(isValid);

      } catch (error) {
        console.error('Error reading file:', error);
        toast.error('Invalid file format', {
        });
      }
    };
    reader.readAsBinaryString(file);
  }
};

  const handleAcceptData = async () => {
    try {
      const response = await postToAPI('solidmanagementdatafromwebsite/', { data: importedData }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.status) {
        setData([...data, ...importedData]);
        setImportedData([]);
        toast.success('Data verified and saved successfully', 'success');
      }
      else
      {
        toast.error("Incorrect Data Format",'error')
      }
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('Invalid Data Format', 'error');
    }
  };

  const handleDeclineData = () => {
    setImportedData([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function for handle the logout functionality.
  const handleLogout = async () => { 
  const response =  await postToAPI("logout/")

    if(response.status)
    {
      navigate("/subapp1")
      localStorage.removeItem('USER_Name');
    }  
};
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

   // Calculate total collection
   const totalpaymentCollection = useMemo(() => {
    return filteredData.reduce((total, entry) => total + parseFloat(entry.paymentReceived) || 0, 0);
  }, [filteredData]);

  const totalbillCollection = useMemo(() => {
    return filteredData.reduce((total, entry) => total + parseFloat(entry.billAmount) || 0, 0);
  }, [filteredData]);

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <Container maxWidth={false} style={{ width: '100%', top: 0, left: 0 }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',margin: '10px' }}>
          <h2 style={{ fontSize: '1.5rem' }}>{headingOFData}</h2>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item>
              <Typography variant="h6" style={{ marginRight: '10px' }}>{userName}</Typography>
            </Grid>
            <img
              src={logo}
              alt="Logo"
              className="profile-logo"
              style={{ width: '30px', marginRight: '10px', cursor: 'pointer' }}
              onClick={handleMenuOpen} // Open menu on logo click
            />
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <TextField
              label="From Date"
              type="date"
              name="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="To Date"
              type="date"
              name="todate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Payment Mode"
              select
              value={paymentMode} // This value will be the default one
              onChange={(e) => setPaymentMode(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: paymentOption.length > 3 ? '120px' : 'auto',
                    },
                  },
                },
              }}
            >
             <MenuItem value="all">All</MenuItem>
              {paymentOption.map((Name) => (
                <MenuItem key={Name} value={Name}>
                  {Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Device"
              select
              value={device}
              fullWidth
              onChange={(e) => setDevice(e.target.value)}
              variant="outlined"
              size="small"
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: deviceOptions.length > 3 ? '120px' : 'auto',
                    },
                  },
                },
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {deviceOptions.map((user_name) => (
                <MenuItem key={user_name} value={user_name}>
                  {user_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Search"
              fullWidth
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Grid item xs="auto">
        <Tooltip title="Copy Data" arrow>
              <IconButton onClick={handleCopyToClipboard} size="small" disabled={filteredData.length === 0}>
                <CopyAll />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Print PDF" arrow>
              <IconButton onClick={handlePrintPDF} size="small" disabled={filteredData.length === 0}>
                <Print />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Save as Excel" arrow>
              <IconButton onClick={handleSaveAsExcel} size="small" disabled={filteredData.length === 0}>
                <Description />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Save as CSV" arrow>
              <IconButton onClick={handleSaveAsCSV} size="small" disabled={filteredData.length === 0}>
                <InsertDriveFile />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Save as PDF" arrow>
              <IconButton onClick={handleSaveAsPDF} size="small" disabled={filteredData.length === 0}>
                <Save />
              </IconButton>
            </Tooltip>
          </Grid>
        <Grid item xs="auto">
        <Tooltip title="Import Data">
            <label htmlFor="file-upload">
              <input
                id="file-upload"
                type="file"
                accept=".xlsx, .xls, .csv"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <IconButton size="small" component="span">
                <CloudUpload />
              </IconButton>
            </label>
          </Tooltip>
          
        </Grid>
        </Grid>
        <Paper style={{ marginTop: '20px', flexGrow: 1, overflow: 'auto' }}>
        <TableContainer component={Paper} style={{ maxHeight: '330px', overflow: 'auto' }}>
        <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ 
                    fontSize: '1rem', 
                    padding: '20px', 
                    borderRight: '1px solid #ddd', 
                    fontWeight: 'bold', 
                    whiteSpace: 'nowrap', // Prevent text wrapping
                    backgroundColor: '#007bff', // Set blue background
                    color: '#fff' // Set text color to white for better contrast
                  }}
                  title=""  // Ensure title attribute is empty or not set

                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
            <TableBody {...getTableBodyProps()}>
              {tablePage.map(row => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <TableCell 
                        {...cell.getCellProps()} 
                        style={{ fontSize: '0.8rem', padding: '4px', borderRight : '1px solid rgba(224, 224, 224, 1)',textAlign: 'center' }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
        <Grid container spacing={1} xs = "auto" style={{ marginTop: '10px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} >
        <Grid item xs="auto">
        <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptData}
            disabled={importedData.length === 0 || !isDataValid}
            style={{ marginLeft: '10px' }}
          >
            Accept
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeclineData}
            disabled={importedData.length === 0 || !isDataValid}
            style={{ marginLeft: '10px' }}
          >
            Decline
          </Button>
        </Grid>
         
        <Grid container spacing={1} style={{ marginTop: '6px', display: 'flex', alignItems: 'center', flexWrap: 'wrap',
          
         }}>
        <Grid item xs="auto" >
        <TextField
          label="Total Bill Collection"
          size="small"
          value={totalbillCollection.toFixed(2)}
          InputProps={{ readOnly: true }} // Optionally make it read-only
         />
        </Grid>

        <Grid item xs="auto" style={{flexGrow: 1 }}>
         <TextField
          label="Total Received Collection"
          size="small"
          value={totalpaymentCollection.toFixed(2)}
          InputProps={{ readOnly: true }} // Optionally make it read-only
         />
        </Grid>


          <Grid item xs="auto" style={{ textAlign: 'right' }}>
            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer position="top-left" autoClose={3000} hideProgressBar theme="colored" />
    </>
  );
};

export default DataTable;
